#dataPicker{
    padding: 0 5px;
    border-radius: 5px;
    display: flex;
    width: max-content;
    /* flex-direction: column; */
    align-items: center;
    gap: 5px;
    margin: 0 5px;
    background-color: var(--schiefGray);
}
#dataPicker .container{
    display: flex;
}
#dataPicker .title{
    color: var(--schiefBlue);
    margin-bottom: 5px;
}
#dataPicker button{
    margin-top: 10px;
    background-color: var(--schiefRed);
    border: none;
    color: var(--schiefGray);
    padding: 5px 7px;
    cursor: pointer;
    border-radius: 5px;
}
#dataPicker label, #dataPicker button{
align-self: center;}

#dataPicker input {
    text-align: center;
}

#dataPicker .field {
    display: flex;
    gap: 5px;
    margin: 8px;
    flex-direction: column;
    align-items: center;
}