#paymentsTable {
    margin: 10px 0;
    background-color: var(--schiefGray);
    min-width: 550px;
    border-bottom: 2px solid var(--schiefRed);
}

#paymentsTable .mainTitle{
    font-weight: bold;
}
#paymentsTable .row{
    border-top: 2px solid var(--schiefRed);
    cursor: pointer;
    width: 100px !important;
}

#paymentsTable td {
    padding: 5px;
    border: 1px solid gray;
    color: var(--schiefBlue);
}
#paymentsTable .hideShowLabel {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
}

#paymentsTable .name {
    flex: 1;
    color: var(--schiefRed);
}
#paymentsTable .label {
    font-weight: bold;
}
#paymentsTable .topTitles {
    border: none;
}
#paymentsTable .noBorder {
    border: none;
    border-bottom: 1px solid gray;
}