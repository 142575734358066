#Container{
position: absolute;
height: 100vh;
width: 100vw;
z-index: 4;
top: 0;
left: 0;
}
#createClientForm{
    position: absolute;
    top: 30%;
    left: 40%;
    background-color: var(--azul);
    width: 350px;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    z-index: 5;
    border-radius: 10px;
}
#Container .background{
    position: absolute;
    background-color: rgba(128, 128, 128, 0.404);
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
}
#createClientForm .formTitle{
    color: white;
    margin-bottom: 20px;
}
#createClientForm .formField{
    margin: 5px 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

#createClientForm .formLabel{
    color: white;
}

#createClientForm .checkboxField{
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#createClientForm .checkboxLabel{
    margin: 0 2px;
}
#createClientForm .checkboxContainer{
    display: flex;
    margin: 10px 0;
    flex-direction: 'row';
    flex-wrap: wrap;
}

#createClientForm .btnSubmit{
    padding: 5px;
    margin: 20px;
    cursor: pointer;
}