.button, .form input[type='button']  {
    padding: 5px 10px;
    margin: 10px;
    cursor: pointer;

    &:disabled {
        opacity: 0.6;
    }
}

.postTitleInput{
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    text-align: center;
}
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
    gap: 25px;
    position: relative;
}

.form .textArea {
    width: 500px;
    max-width: 100%;
    height: 300px;
    max-height: fit-content;
    padding: 10px 15px;
    margin: 10px;
    overflow: scroll;
}
.textEditorContainer{
    background-color: white;
    padding: 10px;
    border-radius: 5px;
}