@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	/* font: inherit; */
	vertical-align: baseline;
  font-family: 'Roboto', sans-serif;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
*, *::before, *::after{
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: 'Roboto', sans-serif;
  color: var(--azul);
}

:root{
  --azul: #1d3562;
  --hover: #24427a;
  --vermelho-claro: #d6aaaa;
  --vermelho: #c74242;
  --title: #1d3562;

  --schiefRed: #c74242; 
  --schiefRed2: #C41619; 
  --schiefRed3: #EA4335; 
  --schiefBlue: #003263;
  --schiefBlue2: #142C57;
  --schiefGray: #eeeeee;

  --HeaderFundo: #003263;
  --NavBarFundo: #003263;
  --NavBarBtnsFundo: #003263;
  --NavBarBtnsTexto: #eeeeee;
  --TopBarFundo: #003263;

  --TituloDaPagina: #003263;
  --Subtitulo: #eeeeee;
  --mainBackground: #eeeeee;

  --ContainerInterno: #003263;
  --TituloContainerInterno: #EA4335;

  --GraficosFundo: white;
  --GraficosTitulo: #EA4335;

  --BtnFundo: #003263;
  --BtnExternoFundo: #EA4335;
  --BtnExternoTexto: #eeeeee;
  --BtnTexto: #eeeeee;

  --TabelaFundo: #eeeeee;
  --TabelaTexto: black;
  --TabelaTituloFundo: white;
  --TabelaTitulo: #EA4335;
  --TabelaLinhaFundo: #eeeeee;

  --HomeBtnsFundo: white;
  --HomeBtnsIcon: #EA4335;
  --HomeBtnsTitle: #003263;
 
}