.button { 
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--schiefBlue);
    box-shadow: 1px 1px 1px var(--schiefBlue);
    cursor: pointer;
    transition: all .3s ease;

    &:hover{
        border-color: var(--schiefRed);
        box-shadow: 1px 1px 1px var(--schiefRed)
    }

    &:active {
        background-color: rgba(128, 128, 128, 0.386);
    }
}

.container {
    display: flex;
    width: 100%;
    max-width: 1000px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.field {
    border: 1px solid #ccc;
    box-shadow: 1px 1px 2px gray;
    padding: 5px;
    &:hover {
        color: var(--schiefRed);
        box-shadow: 1px 1px 2px var(--schiefRed);
    }
}

.field, .field * {
    cursor: pointer;
    &:hover {
        color: var(--schiefRed);
    }
}