#chat .clientMessageContainer{
    align-self: flex-start;
}

#chat .schiefMessageContainer{
    align-self: flex-end;
}
#chat .messageContainer{
    margin: 0;
}
.messageContainer{
    display: flex;
    margin: auto 5px;
}
.schiefIcon {
    width: 30px;
}
.clientIcon {
    width: 30px;
}

.message{
    color: white;
    margin: auto 5px;
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(36, 223, 76, 0.426);
    display: flex;
}
.time {
    font-size: 9px;
    margin: 0 5px;
    color: white;
    align-self: flex-end;
}
.clientMessage{
    background-color: rgba(223, 36, 36, 0.426);
}