.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    gap: 10px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.179);
    border-radius: 5px;
    z-index: 999;
}
.closemodal{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100vw;
    height: 100vh;
}
.centerContainer h4{
    text-transform: uppercase;
    color: #eee;
    font-weight: 500;
    margin-bottom: 10px;
}
.centerContainer{
    z-index: 2;
    display: flex;
    flex-direction: column;
    background-color: var(--azul);
    padding: 20px;
    border-radius: 5px;
    min-width: 400px;
}
.centerContainer * {
    text-align: center;
}

.field {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0 ;

}
.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.row div:first-child{
    flex: 1;
}

.field label {
    color: white;
    font-size: 15px;
}
.field input{
    padding: 3px;
    width: 100%;
}
.field select {
    padding: 3px;
    width: 100px ;
    text-transform: uppercase;
}

.field select option {
    text-transform: uppercase;
}
.centerContainer button {
    width: max-content;
    padding: 3px 10px;
    border-radius: 5px;
    border: 1px solid transparent;
    align-self: center;
    margin-top: 10px;
    cursor: pointer;
    transition: all .2s;

    &:hover{
        border-color: white;
        color: white;
        background-color: var(--azul);
    }
}