#login {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 0 2rem;
    position: relative;
    background-image: url('../../images/bg-login.jpg');
    background-size: cover;
}

#login .form {
    background-color: var(--azul);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 300px;
    max-width: 300px;
    min-height: 250px;
    max-height: 500px;
    position: relative;
}

#login .formTitle{
    margin-bottom: 40px;
    font-size: 25px;
    color: white;
}

#login .field {
    padding: 0.5rem 1rem;
    display: flex;
}

#login .form .login-logo{
    width: 50px;
    position: absolute;
    top: -20px;
    left: 130px;
    border-radius: 50%;
    border: 3px solid white;
    box-shadow: 3px 3px 4px black;
    background-color: white;
}
#login .form .material-symbols-outlined{
    color: var(--azul);
}

#login .field label{
    display: block;
    text-align: center;
}
#login .field .div-icons{
    background-color: #dfdfdf;
    height: 1.5rem ;
    border: none;
}

#login .field input {
    width: 100%;
    height: 1.5rem ;
    padding-left: 0.4rem;
    border: none;
}

#login .actions{
    text-align: center;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
}

#login .actions button {
    width: 100%;
    height: 2rem;
    border-width: 0.1rem;
    border: none;
    background-color: aliceblue;
    color: black;
    font-weight: 500;
    font-size: 12px;
}

#login .actions button:hover {
    background-color: rgb(215, 233, 250);
    color: black;
}

#login .actions button:disabled, #login .field input:disabled{
    background-color: #acabab;
}

#login .actions button, #login .form{
    border: none;
}

.error-message{
    margin-bottom: 1rem;
    color: red;
    font-size: 0.8rem;
}

#login .disabled {
    display: none
}