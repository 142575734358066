.container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.prompt{
    font-size: 14px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    & textarea{
        width: 500px;
        min-height: 250px;
        padding: 10px;
    }
}

.subtitle{ 
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: bold;
    font-size: 18px;
    & span {
        font-size: 14px;
        border: 1px solid gray;
        box-shadow: 1px 1px 2px var(--schiefBlue);
        padding: 5px;
    }
}

.visibleIcon{
    cursor: pointer;
    background-color: none !important;
    box-shadow: none !important;
}

.title {
    margin-bottom: 50px;
    font-size: 20px;
    font-weight: bold;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.listItem{
    transition: all .2s;
    border: 3px solid transparent;
    background-color: var(--schiefBlue);
    color: white;
    padding: 5px;
    margin: 0 20px;
    cursor: pointer;

    &:hover{
        border-color: var(--schiefRed);
    }
}