#chat {
    position: fixed;
    right: 0;
    bottom: 0;
    height: 500px;
    width: 500px;
    background-color: var(--azul);
    color: white;
    display: flex;
    flex-direction: column;
}
.chatHeader{
    box-shadow: 1px 0px 1px #ccc;
}

.openChatBtn{
    cursor: pointer;
    text-decoration: underline;
}
#chat .chatBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
    gap: 5px;
}

#chat .marginAutoContainer {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    width: 100%;
}

#chat .chatInput input{
    width: 100%;
    height: 30px;
    padding-left: 5px;
    border-radius: 10px;
}
#chat .chatInput {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
}
.icon {
    color: white;
    margin-top: 8px;
    cursor: pointer;
}