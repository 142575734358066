#fullinfo {
    display: flex;
    width: 100%;
    flex-direction: column;
}

#fullinfo .card{
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: rgba(255, 255, 255, 0.315);
    border: 2px solid var(--azul);
    margin: 10px;
    padding: 5px;
    border-radius: 10px;
    align-items: center;
    box-shadow: 3px 3px 5px gray;
    border-color: transparent;
    text-align: center;
    flex-wrap: wrap;
}
#fullinfo .row{
    align-items: center;
    gap: 10px;
    display: flex;
}

#fullinfo .projectsContainer {
    display: flex;
    flex-wrap: wrap;
}
#fullinfo .emptyCard{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#fullinfo .projectCard{
    position: relative;
    width: fit-content;
    min-width: 200px !important;
    padding: 15px;
    /* border: 2px solid var(--vermelho); */
}

#fullinfo .noProjectCard{
    padding: 20px;
    width: 100%;
}
#fullinfo .editIcon .tooltip{
    position: absolute;
    top: 0;
    background-color: white;
    font-size: 13px;
    padding: 3px;
    border-radius: 5px;
    right: -100%;
    display: none;
}
#fullinfo .editIcon{
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}
#fullinfo .editIcon:hover .tooltip{
    display: inline-block;
}

#fullinfo .column{
    flex-direction: column !important;
}

#fullinfo .clientCard{
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#fullinfo .infoText {
    gap: 5px;
    display: flex;
    align-items: center;
    color: var(--azul) !important;
}
#fullinfo .infoText span{
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 13px;
}
.mobile {
display: none !important
}

@media (max-width: 900px){
    .mobile {
        display: flex;
}
}


#fullinfo .border{
    border-right: 1px solid gray !important;
    padding-right: 5px;
}