#equipes .userComponent{
    border: 1px solid #ebebeb;
    background-color: rgb(224, 218, 255);
    padding: 10px;
    margin: 5px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#equipes .teamsContainer{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin: 10px 0;
}

#equipes .teamsContainer button {
    padding: 5px 10px;
    border-radius: 3px;
    border: 2px solid #ccc;
    cursor: pointer;
    transition: all .2s;
    background-color: var(--schiefBlue);
    color: white;
}
#equipes .teamsContainer button:hover {
    background-color: transparent;
    color: var(--schiefBlue);
    border-color: var(--schiefBlue);
}
#equipes .field{
    margin: 3px 0;
}
#equipes .teamsField label {
    margin: 0 2px;
    padding: 3px;
    cursor: pointer;
}