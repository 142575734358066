#clients {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
}

#clients ul{
    list-style: none;
}
#clients .title{
    font-size: 25px;
    text-align: center;
    margin: 10px 0;
    color: white;
}

#clients .listTitleRow{
    display: flex;
    align-items: center;
}

#clients .addClient{
    margin: 10px;
    color: white;
    border-radius: 50px;
    border: 2px solid white;
    padding: 2px 8px;
    cursor: pointer;
    position: relative;
}
#clients .addClient:hover .helptext{
    /* display: inline-block; */
    opacity: 1;
}
#clients .helptext{
    width: max-content;
    /* border: 1px solid black; */
    text-align: center;
    font-weight: 300;
    font-size: 13px;
    white-space: pre-line;
    opacity: 0;
    top: 20px;
    right: -40px;
    color: white;
    background-color: gray;
    padding: 3px;
    transition: all 0.3s;
    position: absolute;
}
#clients .disabled {
    height: 0;
    opacity: 0;
    overflow: hidden;
}
#clients .list {
    list-style: none;
    height: 80vh;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    background-color: var(--vermelho);
}

#clients .list .clientListItem {
    margin: 5px;
    width: 150px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background-color: white;
    text-align: center;
    transition: all 1s;
    height: max-content;
}

#clients .field {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black !important;
}

#clients .show{
    height: max-content;
}

#clients .addForm{
    margin: 10px 0;
}

#clients .noLink{
    text-decoration: none;
}
#clients .label {
    margin: 0 5px;
    font-size: 16px;
    font-weight: bold;
    
}
#clients .text {
    font-size: 13px;
}
#clients .text, #clients .label{
    color: white;
}

#clients .mainName {
    font-weight: 500;
    padding: 5px;;
    border-radius: 50px;
}

#clients .companyLabel{
    color: var(--azul);
    margin: 20px;
}

#clients .submitButton{
    width: 100px;
    height: 50px;
    background-color: var(--azul);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin: 10px;
}

#clients .rowContainer{
    display: flex;
    width: 100% !important;
}

@media (max-width: 600px ) {
    #clients .rowContainer{
        flex-direction: column;
    }
}