.gptContainer {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    gap: 20px;
    height: 600px;
}

.gptInnerContainer {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: row;
}

.chatContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 800px;
    background-color: #52525228;
    overflow: scroll;
    border-radius: 10px;
}

.msgContainer {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    gap: 10px;
    /* background-color: var(--azul); */
    /* color: black; */
    padding: 10px;
    border-radius: 10px;
    margin-right: 20px;
    max-width: 80%;
    line-height: 1.4em;
}

.msgContainer:first-child {
    margin-top: 15px;
}

.ai {
    align-self: start;
    margin-right: 0px;
    margin-left: 20px;
}

.msgInputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 800px;
    min-height: 100px;
    background-color: #52525228;
    overflow: scroll;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 12px;
}

.msgSubmitButton {
    width: 100%;
    margin-top: 10px;
    background-color: var(--azul);
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.msgSubmitButton:hover {
    background-color: white;
    color: var(--azul);
}

.gptNavContainer {
    width: 800px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
}

.gptCard {
    padding: 10px;
    /* background-color: rgba(99, 97, 97, 0.726); */
    border-radius: 10px;
    border: var(--azul) 1px solid;
    cursor: pointer;
}

.selected {
    background-color: var(--schiefRed);
    color: white;
}