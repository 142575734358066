.form {
    background-color: var(--schiefBlue);
    display: flex;
    flex-direction: column;
    max-width: 500px;
    height: fit-content;
    padding: 20px;
    align-items: center;
    justify-content: center;
}

.label {
    font-size: 20px;
    color: white !important;
    margin: 5px;
}

.form textarea{
    padding: 10px;
    width: 400px;
    min-height: 200px;
}

.form .textInput {
    padding: 10px;
    width: 400px;
}

.form input[type="submit"]{
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
}