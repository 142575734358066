.teamTitle{
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
}

.membersContainer{
    display: flex;
    flex-wrap: wrap;
    max-width: 50;
    justify-content: center;
}

.teamContainer{
    border: 2px solid #ccc;
    padding: 30px 0 ;
}