
@media (max-width: 600px) {
    #home .btnTitle {
        font-size: 13px !important;}

    #home .btnsContainer {
        max-width: 100vw !important;
    }

    #home .homeBtns {
        height: 85px !important;
        width: 85px !important;
    }
    #home .emBreve {
        font-size: 10px;
        top: 0 !important;
        right: 28px !important;
        margin: 0;
    }
    #home .btnIcon{
        font-size: 25px !important;
    }
}
#home {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
}

#home .title{
    font-size: 25px;
    text-align: center;
    margin: 10px;
    background-color: var(--azul);
    padding: 10px 20px;
    border-radius: 50px;
    color: white;
}

#home .welcome {
    font-size: 20px;
    margin: 20px;
    text-align: center;
}

#home .btnsContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    width: 100vw;
    justify-content: center;
}

#home .homeBtns {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(312deg, rgba(198, 195, 212, 0.25) 40%, rgba(139, 149, 170, 0.25) 75%);
    box-shadow: 1px 1px 2px black;
    border-radius: 5px;
    height: 130px;
    width: 250px;
    margin: 20px;
    /* padding: 0 3px !important; */
}

#home .homeBtns:hover{
    background-color: var(--azul);
}

#home .emBreve{
    opacity: 0;
    position: absolute;
    top: 30px;
    right: 30px;
    color: white;
    text-shadow: 1px 1px 1px black;
    background-color: var(--vermelho);
    padding: 10px;
    border-radius: 50px;
    transition: all 0.3s;
}

#home .buttonHover{
    position: relative;
}
#home .buttonHover:hover .emBreveOn{
    opacity: 1;
}

#home .homeBtns:hover .btnTitle, #home .homeBtns:hover .btnIcon{
    color: white;
}

#home .btnIcon{
    font-size: 40px;
}
#home .link {
    text-decoration: none !important
}

#home .btnTitle {
    font-size: 18px;
    text-align: center;
}