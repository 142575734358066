.mainContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.mainContainer h1{
    font-size: 25px;
    font-weight: bold;
    margin: 20px 0;
}

.bodyContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    max-width: 1000px;
}

.formContainer, .generatedContainer{
    display: flex;
    flex: 1;
}

.formContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.generatedContainer {
    flex-direction: column;
    align-items: center;
}

.title{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
}